import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import deTranslation from '@/locales/de.json'
import enTranslation from '@/locales/en.json'
import esTranslation from '@/locales/es.json'
import plTranslation from '@/locales/pl.json'
import ruTranslation from '@/locales/ru.json'
import uaTranslation from '@/locales/ua.json'

export type TranslationResource = typeof plTranslation
export type TranslationPath = RecursiveKeyOf<TranslationResource>

// Helper type to generate all possible dot notation paths from a nested object
type RecursiveKeyOf<TObj extends object> = {
	[TKey in keyof TObj & (string | number)]: TObj[TKey] extends object
		? `${TKey}` | `${TKey}.${RecursiveKeyOf<TObj[TKey]>}`
		: `${TKey}`
}[keyof TObj & (string | number)]

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: process.env.NODE_ENV !== 'production',
		resources: {
			pl: { translation: plTranslation },
			en: { translation: enTranslation },
			de: { translation: deTranslation },
			es: { translation: esTranslation },
			ru: { translation: ruTranslation },
			ua: { translation: uaTranslation },
		},
		lng: 'pl',
		fallbackLng: 'pl',
		load: 'languageOnly',
		detection: {
			order: ['localStorage', 'navigator'],
			caches: ['localStorage'],
		},
	})

export default i18n
